import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Nav } from "react-bootstrap"
import "./header.scss"
import Logo from "../images/logo.png"
import { motion } from "framer-motion"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  FaHome,
  FaUser,
  FaCode,
  FaBriefcase,
  FaIdCard,
  FaLinkedin,
  FaGithub,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa"

// let delay = 0
// let length = 0
//
// const onExit = (exit, node) => {
//   console.log("exit")
//   console.log(exit, node)
// }
// const onEnter = () => {
//   console.log("enter")
// }


const Header = ({ siteTitle }) => (
  <header className="flex-column nav-bar">
    <Nav>
      <motion.div whileTap={{ scale: 0.7 }}>
        <Nav.Item className="brand"><Link to="/">
          <img src={Logo} alt="Jaykch" className="logo" />
          <span>Jaykch</span>
        </Link></Nav.Item>
      </motion.div>
    </Nav>
    <Nav defaultActiveKey="/" className="navigation">
      <Nav.Item>
        <Link to="/" activeClassName="active">
          <FaHome />
          <motion.div whileTap={{ scale: 0.7 }}>
            <span>Home</span>
          </motion.div>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about" activeClassName="active">
          <FaUser />
          <motion.div whileTap={{ scale: 0.7 }}>
            <span>About</span>
          </motion.div>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/skills" activeClassName="active">
          <FaCode />
          <motion.div whileTap={{ scale: 0.7 }}>
            <span>Skills</span>
          </motion.div>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/portfolio" activeClassName="active">
          <FaBriefcase />
          <motion.div whileTap={{ scale: 0.7 }}>
            <span>Work</span>
          </motion.div>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/contact" activeClassName="active">
          <FaIdCard />
          <motion.div whileTap={{ scale: 0.7 }}>
            <span>Contact</span>
          </motion.div>
        </Link>
      </Nav.Item>
    </Nav>
    <Nav className="social-links">
      <motion.div whileTap={{ scale: 0.7 }}>
        <Nav.Item><OutboundLink href="https://github.com/jaykch" target="_blank"
                                rel="noopener noreferrer"><FaGithub /></OutboundLink></Nav.Item>
      </motion.div>
      <motion.div whileTap={{ scale: 0.7 }}>
        <Nav.Item><OutboundLink href="https://www.linkedin.com/in/jaykch/" target="_blank"
                                rel="noopener noreferrer"><FaLinkedin /></OutboundLink></Nav.Item>
      </motion.div>
      <motion.div whileTap={{ scale: 0.7 }}>
        <Nav.Item><OutboundLink href="https://www.instagram.com/jaykch/" target="_blank"
                                rel="noopener noreferrer"><FaInstagram /></OutboundLink></Nav.Item>
      </motion.div>
      <motion.div whileTap={{ scale: 0.7 }}>
        <Nav.Item><OutboundLink href="https://www.youtube.com/channel/UC4F8pEoZgQKES4REdx8Ma2A" target="_blank"
                                rel="noopener noreferrer"><FaYoutube /></OutboundLink></Nav.Item>
      </motion.div>
    </Nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
